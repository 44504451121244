import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Welcome from '../views/Welcome.vue'
import Dictionary from '../views/Dictionary.vue'
import CityCoord from '../views/CityCoord.vue'
import I18nConfig from '../views/I18nConfig.vue'
import UserManager from '../views/UserManager.vue'
import BikeManager from '../views/BikeManager.vue'
import VboxList from '../views/VboxList.vue'
import VboxSimulate from '../views/VboxSimulate.vue'
import VersionList from '../views/VersionList.vue'
import PackageManager from '../views/PackageManager.vue'
import BikeVersionList from '../views/BikeVersionList.vue'
import RichText from '../views/RichText.vue'
import HelpGuide from '../views/HelpGuide.vue'
import DoctorUser from '../views/DoctorUser.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Login
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home,
    redirect: '/home/vboxlist',
    children: [
      {path: 'welcome', component: Welcome},
      {path: 'dictionary', component: Dictionary},
      {path: 'citycoord', component: CityCoord},
      {path: 'i18n', component: I18nConfig},
      {path: 'userlist', component: UserManager},
      {path: 'bikelist', component: BikeManager},
      {path: 'vboxlist', component: VboxList},
      {path: 'vboxsimulate/:imei', name: 'vboxSimulate', component: VboxSimulate},
      {path: 'versionlist', component: VersionList},
      {path: 'packageManager', component: PackageManager},
      {path: 'bikeversionlist', component: BikeVersionList},
      {path: 'richtext', component: RichText},
      {path: 'helpguide',name: 'helpguide', component: HelpGuide},
      {path: 'doctor',name: 'doctor', component: DoctorUser},
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  //document.title = 'ebike'
  next()
})

export default router
