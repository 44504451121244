<template>
    <div class="login_container">
        <div class="login_box">
            <div class="avatar_box">
                <img src="../assets/pics/Vinka.png" alt=""/>
            </div>
            <el-form ref="loginForm" :model="loginForm" :rules="rules" label-width="0px" class="login_form">
                <el-form-item prop="userName">
                    <el-input v-model="loginForm.userName" placeholder="用户名" tabindex = "1" @keyup.enter.native="login">
                        <template slot="prepend">
                            <el-button icon="el-icon-user"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="loginForm.password"  type="password"  placeholder="密码" tabindex = "2" @keyup.enter.native="login">
                        <template slot="prepend">
                            <el-button icon="el-icon-lock"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item class="btns">
                    <el-button type="primary" @click="login">登录</el-button>
                    <el-button type="info" @click="resetFields">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return{
                loginForm: {
                    userName: "",
                    password: ""
                },
                rules: {
                    userName: [
                        {required: true, message: '请输入用户名', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur'}
                    ],
                    password: [
                         {required: true, message: '请输入密码', trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            resetFields(){
                this.$refs.loginForm.resetFields()
            },
            login() {
                this.$refs.loginForm.validate((valid) => {
                    if(!valid) return
                    this.$http.post("/user/login", this.loginForm).then(res => {
                        window.sessionStorage.setItem("accessToken", res.data.accessToken)
                        window.sessionStorage.setItem("userName", res.data.userName)
                        this.$router.push("/home")
                        this.$message.success('登录成功')
                    })
                    
                });
            }
        }
    }
</script>

<style scoped>
  .login_container{
      background-color: #2b4b6b;
      height: 100%;
  }
  .login_box{
      width: 450px;
      height: 300px;
      background-color: #fff;
      border-radius: 3px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
  }
  .avatar_box{
      height: 130px;
      width: 130px;
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 50%;
      padding: 10px;
      box-shadow: 0 0 10px #ddd;
      position: absolute;
      left: 50%;
      transform: translate(-50%,-50%);
   }
  img{
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background-color: #eee;
   }
   .login_form {
        position: absolute;
        bottom: 0px;
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;
    }
    .btns {
        display: flex;
        justify-content: flex-end;
    }
</style>
