<template>
    <el-container class="home-container">
        <el-header>
            <div class="header-left-div">
                <img src="../assets/pics/vbox.png" height="25px"/>
                <span class="header-left-span">eBike管理后台</span>
            </div>

            <div class="header-right">
                <div class="header-user-con">
                    <!-- 用户头像 -->
                    <div class="user-avator">
                        <img src="../assets/pics/header.png" />
                    </div>
                    <!-- 用户名下拉菜单 -->
                    <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                        <span class="el-dropdown-link">
                            {{username}}
                            <i class="el-icon-caret-bottom"></i>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <!-- <el-dropdown-item command="user">个人中心</el-dropdown-item> -->
                                <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <!-- <el-button type="info" @click="logout()">退出</el-button> -->
        </el-header>
        <el-container>
            <el-aside :width="isCollapse? '64px' :'200px'">
                 <!-- 折叠条 -->
                <div class="toggle-button" @click="toggleClapse">|||</div>
                <el-menu background-color="#324157" text-color="#bfcbd9" active-text-color="#20a0ff" 
                        :unique-opened="true" :collapse="isCollapse" :collapse-transition="false"
                        :router="true" :default-active="activePath">
                    <!-- 一级菜单 -->
                    <el-submenu v-for="item in menuList" :key="item.id" :index="item.id+''">
                        <template slot="title">
                            <i :class="item.icon"></i>
                            <span>{{item.title}}</span>
                        </template>
                        <!-- 二级菜单 -->
                        <el-menu-item :index="subItem.path" v-for="subItem in item.children" :key="subItem.id" @click="setActivePath(subItem.path)">
                            <template slot="title">
                                <i :class="subItem.icon"></i>
                                <span slot="title">{{subItem.title}}</span>
                            </template>
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
export default {
    data(){
        return {
            isCollapse: false,
            activePath: '/home/vboxlist',
            message: 0,
            username: 'admin',

            menuList: [
            {   
                id: 10,
                icon: "el-icon-mobile-phone",
                path: "/appmanager",
                title: "APP管理",
				children: [
                    {
                        id: 11,
                        path: "/home/versionlist",
                        title: "版本中心",
                    },
                    {
                        id: 12,
                        path: "/home/helpguide",
                        title: "Help & Guide",
                    }
                ]
            },    
            {   
                id: 20,
                icon: "el-icon-bicycle",
                path: "/bikemanager",
                title: "车辆管理",
				children: [
                    {
                        id: 21,
                        path: "/home/bikelist",
                        title: "车辆列表",
                    },
                    {
                        id: 22,
                        path: "/home/vboxlist",
                        title: "VBox列表",
                    },
                    {
                        id: 24,
                        path: "/home/packageManager",
                        title: "Package管理",
                    },
                    {
                        id: 23,
                        path: "/home/bikeversionlist",
                        title: "版本中心",
                    }
                ]
            },
            {
                id: 30,
                icon: "el-icon-user",
                path: "/usermanager",
                title: "用户管理",
				children: [
                    {
                        id: 31,
                        path: "/home/userlist",
                        title: "用户列表",
                    }
                ]
            },
            {
                id: 40,
                icon: "el-icon-setting",
                path: "/config",
                title: "配置中心",
				children: [
                    {
                        id: 41,
                        path: "/home/dictionary",
                        title: "字典管理",
                    },
                    {
                        id: 42,
                        path: "/home/citycoord",
                        title: "城市坐标",
                    },
                    {
                        id: 43,
                        path: "/home/i18n",
                        title: "国际化",
                    }
                ]
            },
            {
                id: 50,
                icon: "el-icon-first-aid-kit",
                path: "/doctor",
                title: "Doctor",
				children: [
                    {
                        id: 51,
                        path: "/home/doctor",
                        title: "用户列表",
                    }
                ]
            }

        ]
        }
    },
    created(){
        //this.getMenus()
        this.activePath = window.sessionStorage.getItem('activePath')
        this.username = window.sessionStorage.getItem('userName')
    },
    methods: {
        logout() {
            window.sessionStorage.clear()
            this.$router.push('/login')
        },
        getMenus() {
            this.$http.get('/menus').then((result) => {
                console.log("请求菜单结果:", result.data.data)
                this.menuList = result.data.data
            });
        },
        toggleClapse() {
            this.isCollapse = !this.isCollapse
        },
        setActivePath(activePath) {
            this.activePath = activePath
            window.sessionStorage.setItem('activePath', activePath)
        },
        handleCommand(command) {
            if(command === 'loginout') {
                this.logout()
            }
        }
    }
}
</script>

<style scoped>
    .el-header{
        background-color: #324157;
        display: flex;
        justify-content: space-between;
        padding-left: 0;
        align-items: center;
        color: #fff;
        font-size: 20px;
    }
    .header-left-div{
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "SF Pro Text";
    }
    .header-left-span{
        margin-left: 15px;
    }
    .header-right {
        float: right;
        padding-right: 50px;
    }
    .header-user-con {
        display: flex;
        height: 70px;
        align-items: center;
    }
    .el-aside{
        background-color: #324157;
    }
    .el-menu{
        border-right: none;
    }
    .el-main{
        background-color: #ffffff;
    }
    .home-container{
        height: 100%;
    }
    .toggle-button{
        background-color: #4A5064;
        font-size: 10px;
        line-height: 24px;
        color: #fff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }

    .btn-bell,
    .btn-fullscreen {
        position: relative;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 15px;
        cursor: pointer;
    }
    .btn-bell-badge {
        position: absolute;
        right: 0;
        top: -2px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #f56c6c;
        color: #fff;
    }
    .btn-bell .el-icon-bell {
        color: #fff;
    }
    .user-name {
        margin-left: 10px;
    }
    .user-avator {
        margin-left: 20px;
    }
    .user-avator img {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .el-dropdown-link {
        color: #fff;
        cursor: pointer;
    }
    .el-dropdown-menu__item {
        text-align: center;
    }
</style>