import axios from 'axios'
import { Message } from 'element-ui'
import { blobValidate } from '../utils/common'
import { saveAs } from 'file-saver'

// 通用下载方法
export function download(url, params, filename) {
    return axios.post(url, params, {
        responseType: 'blob'
    }).then(async (res) => {
        const data = res.data
        const isValid = await blobValidate(data);
        if (isValid) {
            if(!filename) {
                let dispositionStr = res.headers["content-disposition"];
                // 获取文件名
                let dispositionArr = dispositionStr.split(";");
            
                let realFileName = decodeURIComponent(dispositionArr[1]);
                filename = realFileName.split("=")[1];
            }
            
            const blob = new Blob([data])
            saveAs(blob, filename)
        } else {
            const resText = await data.text();
            const rspObj = JSON.parse(resText);
            const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
            Message.error(errMsg)
        }
    }).catch((r) => {
        console.info(r)
        Message.error('下载文件出现错误，请联系管理员！')
    })
}