<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>APP管理</el-breadcrumb-item>
        <el-breadcrumb-item>版本中心</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-row :gutter="5">
            <el-col :xs="8" :sm="6" :md="6" :lg="4" :xl="4">
                <el-select v-model="appId" clearable placeholder="请选择App">
                    <el-option
                    v-for="item in apps"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :xs="8" :sm="6" :md="6" :lg="4" :xl="4">
                <el-select v-model="brandValue" clearable placeholder="请选择brand">
                    <el-option
                    v-for="item in brands"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :xs="8" :sm="6" :md="6" :lg="4" :xl="4">
                <el-select v-model="osValue" clearable placeholder="请选择OS">
                    <el-option
                    v-for="item in systems"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :xs="8" :sm="6" :md="6" :lg="4" :xl="4">
                <el-select v-model="latestValue" clearable placeholder="最新版本">
                    <el-option :key="1" label="是" :value="1"></el-option>
                    <el-option :key="0" label="否" :value="0"></el-option>
                </el-select>
            </el-col>
            <el-col :xs="6" :sm="4" :md="3" :lg="2" :xl="2">
                <el-button type="primary" class="goodsindex-queryInfo-li" size="small" @click="getVersionList">搜索</el-button>
            </el-col>

            <el-col :xs="6" :sm="4" :md="3" :lg="2" :xl="2">
                <el-tooltip class="item" effect="dark" content="上传新版本" placement="top-start">
                    <el-button type="warning" class="goodsindex-queryInfo-li" size="small" @click="addFormVisible = true">发布新版本</el-button>
                </el-tooltip>
            </el-col>
        </el-row>

        <el-table :data="versionPage.records" row-key="id" style="width: 100%" 
            v-loading="loading"
            element-loading-text="同步最新版到固定二维码中..."
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :header-cell-style="{background:'#F2F2F4', 'text-align':'center'}" border>
            <el-table-column type="index" label="编号" align="center"></el-table-column>
             <el-table-column prop="appName" label="App" align="center"  width="100px">
            </el-table-column>
            <el-table-column prop="brandName" label="Brand" align="center"  width="100px">
            </el-table-column>
            <el-table-column prop="osDesc" label="OS" align="center" width="90px">
            </el-table-column>
            <el-table-column prop="version" label="版本号" align="center" width="90px">
            </el-table-column>
            <el-table-column prop="packageUrl" label="升级包" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="releaseNote" label="描述" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="forceFlag" label="强制更新" align="center"  width="80px">
                <template slot-scope="scope">
                    <el-tag size="mini" type="success" v-if="scope.row.forceFlag === 1">是</el-tag>
                    <el-tag size="mini" type="info" v-else>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="latest" label="最新版" align="center" width="80px">
                <template slot-scope="scope">
                    <el-tag size="mini" type="success" v-if="scope.row.latest">是</el-tag>
                    <el-tag size="mini" type="info" v-else>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="googlePlayFlag" label="Google Play已上架" align="center" width="140px">
                <template slot-scope="scope">
                    <el-tag size="mini" type="success" v-if="scope.row.googlePlayFlag">是</el-tag>
                    <el-tag size="mini" type="info" v-else>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center"  width="80px">
                <template slot-scope="scope">
                    <el-tag size="mini" type="warning" v-if="scope.row.status === 1">待发布</el-tag>
                    <el-tag size="mini" type="success" v-else>已发布</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center">
            </el-table-column>
            <el-table-column prop="updateTime" label="更新时间" align="center">
            </el-table-column>

            <el-table-column label="操作"  align="center">
              <template slot-scope="scope">
                <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button type="text" v-show="scope.row.status === 1" size="mini" icon="el-icon-check" @click="handleDeploy(scope.row.id)">发布</el-button>
                <el-button type="text" v-show="scope.row.status === 2" size="mini" icon="el-icon-arrow-down" @click="handleUnDeploy(scope.row.id)">下架</el-button>
                <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(scope.row.id)">删除</el-button>
                <el-button type="text" v-show="scope.row.packageUrl" size="mini" icon="el-icon-picture-outliney" @click="handleQrCode(scope.row)">二维码</el-button>
              </template>
            </el-table-column>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="versionPage.pageNumber" :page-sizes="[1, 2, 5, 10]" :page-size="versionPage.pageSize" 
            layout="total, sizes, prev, pager, next, jumper" :total="versionPage.total">
        </el-pagination>

        <el-dialog
          title="生成二维码"
          :visible.sync="qrCodeVisible"
          width="16%">

          <div class="qrCode-wrap">
            <div ref="qrCode" class="qrCode">
                <vue-qr :logoSrc="qrCode.logoUrl" :logoScale="0.22" :margin="0"  :text="qrCode.packageUrl" :size="200"></vue-qr>
            </div>
          </div>
          
            <span slot="footer" class="dialog-footer">
              <el-button @click="qrCodeVisible = false">取 消</el-button>
              <el-button type="primary" @click="downloadQrcode()">下 载</el-button>
            </span>
        </el-dialog>

        <el-dialog
          title="添加新版本"
          :visible.sync="addFormVisible"
          width="60%"
          @close="resetAddFormDatas">

            <el-form ref="addFormRef" :model="formData" label-width="180px">
              <el-form-item label="App: " prop="appId">
                <el-select v-model="formData.appId" clearable placeholder="请选择App">
                    <el-option
                    v-for="item in apps"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Brand: " prop="brandId">
                <el-select v-model="formData.brandId" clearable placeholder="请选择brand">
                    <el-option
                    v-for="item in brands"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="OS: " prop="os">
                <el-select v-model="formData.os" clearable placeholder="请选择OS">
                    <el-option
                    v-for="item in systems"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="版本号: " prop="version">
                <el-input v-model="formData.version" class="dia-input"></el-input>
              </el-form-item>
              <el-form-item label="是否强更: " prop="forceFlag">
                <el-radio  @change="$forceUpdate()" v-model="formData.forceFlag" label="1">是</el-radio>
                <el-radio @change="$forceUpdate()" v-model="formData.forceFlag" label="0">否</el-radio>
              </el-form-item>
              <el-form-item label="Google Play已上架: " prop="googlePlayFlag">
                <el-radio  @change="$forceUpdate()" v-model="formData.googlePlayFlag" label="true">是</el-radio>
                <el-radio  @change="$forceUpdate()" v-model="formData.googlePlayFlag" label="false">否</el-radio>
              </el-form-item>
              <el-form-item label="发布信息: " prop="releaseNote">
                <el-input type="textarea" :rows="4" v-model="formData.releaseNote"></el-input>
              </el-form-item>
              <el-form-item label="升级包: " prop="packageUrl">
                <el-upload ref="addUploadRef"
                    class="upload-demo"
                    drag
                    :limit="1"
                    :file-list="packageUrls"
                    :action="fileUploadUrl"
                    :on-success="handleSuccess">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传apk文件，且不超过100M</div>
                </el-upload>
              </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
              <el-button @click="addFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="addVersion()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
          title="编辑"
          :visible.sync="editFormVisible"
          width="60%"
          @close="resetEditFormDatas">
            
            <el-form ref="editFormRef" :model="formData" label-width="180px">
              <el-form-item label="App: " prop="appId">
                <el-select v-model="formData.appId" clearable placeholder="请选择App">
                    <el-option
                    v-for="item in apps"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Brand: " prop="brandId">
                <el-select v-model="formData.brandId" clearable @change="$forceUpdate()" placeholder="请选择brand">
                    <el-option
                    v-for="item in brands"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="OS: " prop="os">
                <el-select v-model="formData.os" clearable @change="$forceUpdate()" placeholder="请选择OS">
                    <el-option
                    v-for="item in systems"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="版本号: " prop="version">
                <el-input v-model="formData.version" @input="$forceUpdate()"  class="dia-input"></el-input>
              </el-form-item>

              <el-form-item label="是否强更: " prop="forceFlag">
                <el-radio-group v-model="formData.forceFlag" @change="$forceUpdate()">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="Google Play已上架: " prop="googlePlayFlag">
                <el-radio-group v-model="formData.googlePlayFlag"  @change="$forceUpdate()">
                    <el-radio label="true">是</el-radio>
                    <el-radio label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="发布信息: " prop="releaseNote">
                <el-input type="textarea" :rows="4" v-model="formData.releaseNote" @input="$forceUpdate()"></el-input>
              </el-form-item>
              <el-form-item label="升级包: " prop="packageUrl">
                <el-upload ref="editUploadRef"
                    class="upload-demo"
                    drag
                    :limit="1"
                    :file-list="packageUrls"
                    :action="fileUploadUrl"
                    :on-success="handleSuccess">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传apk文件，且不超过100M</div>
                </el-upload>
              </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
              <el-button @click="resetEditFormDatas">取 消</el-button>
              <el-button type="primary" @click="editVersion()">确 定</el-button>
            </span>
        </el-dialog>
    </el-card>
  </div>
</template>

<script>
import Constant from '../components/Constant.vue'
import VueQr from 'vue-qr'
import html2canvas from 'html2canvas'

export default {
    data() {
        return {
            brands: Constant.brands,
            systems: Constant.systems,
            yesNo: Constant.yesNo,
            apps: Constant.APP_LIST,
            loading: false,
            versionPage: {},
            queryParams: {},
            appId: 1,
            brandValue: '',
            osValue: '',
            latestValue: '',
            fileUploadUrl: this.$apiBaseUrl + 'file/upload-package',
            formData: {},
            packageUrls: [],
            addFormVisible: false,
            editFormVisible: false,

            qrCodeVisible: false,
            qrCode: {
                "logoUrl": "",
                "packageUrl": ""
            }
        }
    },

    components: {
        VueQr
    },

    methods: {
        getVersionList() {
            this.queryParams.brandId = this.brandValue
            this.queryParams.appId = this.appId
            this.queryParams.os = this.osValue
            this.queryParams.latest = this.latestValue
            console.log("queryParams: ", this.queryParams)
            this.$http.get('/app/versions', {
                params: this.queryParams
            })
            .then(res => {
                this.versionPage = res.data
            })
        },

        handleSizeChange(val) {
            this.queryParams.pageSize = val
            this.getVersionList()
        },

        handleCurrentChange(val) {
            this.queryParams.pageNumber = val
            this.getVersionList()
        },

        handleEdit(row) {
            console.log("编辑数据: ", row)
            this.editFormVisible = true
            this.formData.id = row.id
            this.formData.appId = row.appId
            this.formData.brandId = row.brandId
            this.formData.os = row.os
            this.formData.version = row.version
            this.formData.forceFlag = row.forceFlag + ""
            this.formData.releaseNote = row.releaseNote
            this.formData.packageUrl = row.packageUrl
            this.formData.googlePlayFlag = row.googlePlayFlag + ""
        },

        handleDeploy(id) {
            this.loading = true
            this.$http.get('/app/version/deploy/'+id)
            .then(res => {
                this.loading = false
                this.$message.success('发布成功')
                this.getVersionList()
            })
        },

        handleUnDeploy(id) {
            this.$http.get('/app/version/undeploy/'+id)
            .then(res => {
                this.$message.success('下架成功')
                this.getVersionList()
            })
        },

        handleDelete(id) {
            this.$http.delete('/app/version/'+id)
            .then(res => {
                this.$message.success('删除成功')
                this.getVersionList()
          })
        },

        resetAddFormDatas() {
            this.$refs.addFormRef.resetFields()
            this.$refs.addUploadRef.clearFiles()
            this.formData = {}
        },
        resetEditFormDatas() {
            this.editFormVisible = false
            this.$refs.editFormRef.resetFields()
            this.$refs.editUploadRef.clearFiles()
            this.formData = {}
        },
        addVersion() {
             this.$http.post('/app/version', this.formData)
            .then(res => {
                this.addFormVisible = false
                this.$message.success('添加成功')
                this.getVersionList()
            })
        },

        editVersion() {
             this.$http.post('/app/version', this.formData)
            .then(res => {
                this.editFormVisible = false
                this.$message.success('编辑成功')
                this.getVersionList()
            })
        },

        handleRemove(file, fileList) {
            this.formData.packageUrl = ''
        },
        
        handleSuccess(response, file, fileList) {
            this.formData.packageUrl = response.data.url
        },

        handleQrCode(row) {
            this.qrCodeVisible = true
            
            this.qrCode.packageUrl = row.packageUrl
            console.log("====packageUrl====", this.qrCode.packageUrl)
            if(row.appId == 5) {
                if(this.$envFlag == 'test') {
                    this.qrCode.logoUrl = require("../assets/logo/doctor-test.png")
                } else {
                    this.qrCode.logoUrl = require("../assets/logo/doctor-pro.png")
                }
                 
            } else if(row.brandId == 100) {
                this.qrCode.logoUrl = require("../assets/logo/vinka.png")
            } else if(row.brandId == 108) {
                this.qrCode.logoUrl = require("../assets/logo/108.png")
            } else if(row.brandId == 111) {
                this.qrCode.logoUrl = require("../assets/logo/111.png")
            } else if(row.brandId == 115) {
                this.qrCode.logoUrl = require("../assets/logo/115.png")
            }
        },

        downloadQrcode() {
            html2canvas(this.$refs.qrCode).then(canvas => {
                let link = document.createElement('a')
                link.href = canvas.toDataURL()
                link.setAttribute('download', 'qrCode.png')
                link.style.display = 'none'
                document.body.appendChild(link)
                link.click()
            })
        }
    },

    created() {
        this.getVersionList()
    }
}
</script>

<style scoped>
    .qrCode-wrap{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .qrCode{
        width: 200px;
        height: 200px;
    }
</style>