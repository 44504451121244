<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>配置中心</el-breadcrumb-item>
        <el-breadcrumb-item>城市坐标</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-row :gutter="5">
            <el-col :span="3">
              <el-select v-model="queryParams.cityType" clearable placeholder="请选择城市类型">
                    <el-option
                    v-for="item in cityTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="3">
              <el-select v-model="queryParams.countryName" clearable placeholder="请选择国家">
                    <el-option
                    v-for="item in countryList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
        
            <el-col  :span="3">
                <el-input placeholder="请输入省名称" v-model="queryParams.provinceName" clearable></el-input>
            </el-col>
            <el-col  :span="3">
               <el-input placeholder="请输入市名称" v-model="queryParams.cityName" clearable></el-input>
            </el-col>
            <el-col  :span="3">
               <el-input placeholder="请输入区名称" v-model="queryParams.districtName" clearable></el-input>
            </el-col>
            <el-col  :span="3">
                <el-button type="primary" class="goodsindex-queryInfo-li" size="small" @click="getCityCoordList">搜索</el-button>
            </el-col>
            <el-col  :span="3">
                <el-button type="warning" class="goodsindex-queryInfo-li" size="small" @click="addFormVisible = true">新增</el-button>
            </el-col>
        </el-row>

        <el-table :data="resPage.records" row-key="id" style="width: 100%" :header-cell-style="{background:'#F2F2F4', 'text-align':'center'}" border>
            <el-table-column type="index" align="center"></el-table-column>
            <el-table-column prop="countryName" label="国家" width="180" align="center">
            </el-table-column>
            <el-table-column prop="provinceName" label="省" width="180" align="center">
            </el-table-column>
            
            <el-table-column prop="cityName" label="市" align="center">
            </el-table-column>
            <el-table-column prop="districtName" label="区" align="center">
            </el-table-column>
            <el-table-column prop="lon" label="经度" align="center">
            </el-table-column>
            <el-table-column prop="lat" label="纬度" align="center">
            </el-table-column>
            <el-table-column label="操作"  align="center">
              <template slot-scope="scope">
                <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button type="text" size="mini" icon="el-icon-edit" @click="handleCopy(scope.row)">复制</el-button>
                <el-button type="text" size="mini" icon="el-icon-copy-document" @click="handleDelete(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="resPage.pageNumber" :page-sizes="[1, 2, 5, 10]" :page-size="resPage.pageSize" 
          layout="total, sizes, prev, pager, next, jumper" :total="resPage.total">
        </el-pagination>

        <el-dialog
          :title="title"
          :visible.sync="addFormVisible"
          width="40%"
          @close="resetAddFormDatas">
            <el-form ref="addForm" :model="formData" label-width="100px">
              <el-form-item label="城市类型: " prop="cityType">
                <el-select v-model="formData.cityType" clearable @change="$forceUpdate()" placeholder="请选择城市类型">
                    <el-option
                    v-for="item in cityTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item>  
              <el-form-item label="国家: " prop="countryName">
                <el-select v-model="formData.countryName" clearable placeholder="请选择国家">
                    <el-option
                    v-for="item in countryList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item>  
              <el-form-item label="省名称: " prop="provinceName">
                <el-input v-model="formData.provinceName" class="form-input"></el-input>
              </el-form-item>

              <el-form-item label="市名称: " prop="cityName">
                <el-input v-model="formData.cityName" class="form-input"></el-input>
              </el-form-item>

              <el-form-item label="区名称: " prop="districtName">
                <el-input v-model="formData.districtName" class="form-input"></el-input>
              </el-form-item>

              <el-form-item label="经度: " prop="lon">
                <el-input v-model="formData.lon" class="form-input"></el-input>
              </el-form-item>

              <el-form-item label="纬度: " prop="lat">
                <el-input v-model="formData.lat" class="form-input"></el-input>
              </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
              <el-button @click="addFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="add()">确 定</el-button>
            </span>
        </el-dialog>
    </el-card>
  </div>
</template>

<script>
import Constant from '../components/Constant.vue'

export default {
    data() {
        return {
            cityTypes: Constant.city_types,
            countryList: Constant.country_list,

            queryParams: {
                cityType: '',
                countryName: '',
                provinceName: '',
                cityName: '',
                districtName: ''
            },

            resPage: {},

            title: '新增',

            addFormVisible: false,

            formData: {}
        }
    },

    methods: {
        getCityCoordList() {
           
        },

        handleEdit(row) {
            console.log("编辑:", row)
            this.title = "编辑"
            this.formData = {...row}
            this.addFormVisible = true
        },

        handleCopy(row) {
          this.title = "复制"
          this.formData = {...row}
          this.formData.id = ''
          this.addFormVisible = true
        },

        handleDelete(id) {
            
        },
        handleSizeChange(val) {
            this.queryParams.pageSize = val
            this.getCityCoordList()
        },
        handleCurrentChange(val) {
            this.queryParams.pageNumber = val
            this.getCityCoordList()
        },

        resetAddFormDatas() {
            this.$refs.addForm.resetFields()
            this.formData = {}
        },

        add() {
            
        }
    },

    created() {
        this.getCityCoordList()
    }
}
</script>

<style scoped>
    .form-input{
        width: 66%;
    }
</style>