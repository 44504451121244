<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
            <el-breadcrumb-item>Package管理</el-breadcrumb-item>
        </el-breadcrumb>

        <el-card>
            <el-row :gutter="5">
             <el-col :xs="8" :sm="6" :md="6" :lg="4" :xl="4">
                <el-select v-model="queryParams.category" clearable placeholder="请选择Category">
                    <el-option
                    v-for="item in categories"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
             </el-col>
             <el-col :xs="8" :sm="6" :md="6" :lg="4" :xl="4">
                <el-input placeholder="请输入Version ID" v-model="queryParams.versionId" clearable></el-input>
             </el-col>
             <el-col :xs="6" :sm="4" :md="3" :lg="2" :xl="2">
                <el-button type="primary" class="goodsindex-queryInfo-li" size="small" @click="getList">搜索</el-button>
             </el-col>

             <el-col :xs="6" :sm="4" :md="3" :lg="2" :xl="2">
                <el-button type="warning" class="goodsindex-queryInfo-li" size="small" @click="handleAdd">新增</el-button>
             </el-col>
            </el-row>

            <el-table :data="dataPage.records" row-key="packageId" style="width: 100%" :header-cell-style="{background:'#F2F2F4', 'text-align':'center'}" border>
                <el-table-column prop="packageId" label="ID" align="center">
                </el-table-column>
                <el-table-column prop="category" label="Category" align="center">
                </el-table-column>
                <el-table-column prop="versionId" label="Model" width="260" align="center">
                </el-table-column>
                <el-table-column prop="branch" label="Branch" width="260" align="center">
                </el-table-column>
                <el-table-column prop="packageCustomer" label="Customer" align="center">
                </el-table-column>
                <el-table-column prop="packageModel" label="Specification" align="center">
                </el-table-column>
                <el-table-column prop="packageFunction" label="Function"  align="center">
                </el-table-column>
                <el-table-column prop="packageMarket" label="Market" align="center">
                </el-table-column>
                <el-table-column prop="packageComments" label="Comments" align="center">
                </el-table-column>
                <el-table-column prop="createdTime" label="Create Time" align="center">
                </el-table-column>
                <el-table-column label="操作"  align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(scope.row.packageId)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            
            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="dataPage.pageNumber" :page-sizes="[1, 2, 5, 10]" :page-size="dataPage.pageSize" 
            layout="total, sizes, prev, pager, next, jumper" :total="dataPage.total">
            </el-pagination>

            <el-dialog
            :title="title"
            :visible.sync="formVisible"
            width="60%"
            @close="resetFormDatas">
                <el-form ref="formRef" :model="formData" label-width="80px">
                    <el-form-item label="Category: " prop="packageCategory">
                        <el-select v-model="formData.packageCategory" clearable placeholder="请选择Category">
                            <el-option
                            v-for="item in categories"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="Version ID: " prop="versionId">
                        <el-input v-model="formData.versionId" class="dia-input"></el-input>
                    </el-form-item>

                    <el-form-item label="Customer: " prop="packageCustomer">
                        <el-input v-model="formData.packageCustomer" class="dia-input"></el-input>
                    </el-form-item>

                    <el-form-item label="Model: " prop="packageModel">
                        <el-input v-model="formData.packageModel" class="dia-input"></el-input>
                    </el-form-item>

                    <el-form-item label="Function: " prop="packageFunction">
                        <el-input v-model="formData.packageFunction" class="dia-input"></el-input>
                    </el-form-item>

                    <el-form-item label="Market: " prop="packageMarket">
                        <el-input v-model="formData.packageMarket" class="dia-input"></el-input>
                    </el-form-item>

                    <el-form-item label="Comments: " prop="packageComments">
                        <el-input v-model="formData.packageComments" class="dia-input"></el-input>
                    </el-form-item>
                </el-form>

                <span slot="footer" class="dialog-footer">
                <el-button @click="resetFormDatas">取 消</el-button>
                <el-button type="primary" @click="submitPackage">确 定</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>

<script>
import Constant from '../components/Constant.vue'

export default {
    data() {
        return {
            categories: Constant.device_address,
            dataPage: {},
            queryParams: {},
            formVisible: false,
            title: '新增',
            formData: {}
        }
    },

    methods: {

        getList(){
            this.$http.get('/bike/version/package/page', {
                params: this.queryParams
            }).then(res => {
                this.dataPage = res.data
            })
        },

        handleEdit(row) {
            console.log("编辑:", row)
            this.formVisible = true
            this.title = '编辑'
            this.formData = {...row}
        },

        handleDelete(id){
            console.log("删除:", id)
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post('/bike/version/package/del/'+id, {})
                .then(res => {
                    this.$message.success('删除成功')
                    this.getList()
                })
            }).catch(() => {
                this.$message.info('取消删除')
            });
        },

        handleAdd(){
            this.formVisible = true
        },

        handleSizeChange(val) {
            this.queryParams.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.queryParams.pageNumber = val
            this.getList()
        },

        submitPackage() {
            console.log("提交Package:", this.formData)
            this.$http.post('/bike/version/package', this.formData)
                .then(res => {
                    this.formVisible = false
                    this.$message.success('保存成功')
                    this.getList()
                    this.resetFormDatas()
             })
        },

        resetFormDatas() {
            this.$refs.formRef.resetFields()
            this.formData = {}
            this.formVisible = false
        },
        
    },

    created() {
        this.getList()
    }
}
</script>

<style>

</style>