<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>APP管理</el-breadcrumb-item>
        <el-breadcrumb-item>Help & Guide</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>

    <el-row>
        <el-select v-model="brandId" @change="selectBrand" clearable placeholder="请选择brand">
          <el-option
          v-for="item in brands"
          :key="item.value"
          :label="item.label"
          :value="item.value">
          </el-option>
        </el-select>

        <el-button class="uploadBtn" size="mini" type="primary" @click="uploadWord">
          导入word<i class="el-icon-upload el-icon--right">

          </i></el-button>
    </el-row>
    

    <el-tabs type="border-card" v-model="activeIndex" @tab-click="tabClick">
        <el-tab-pane label="编辑" name="1">
          <template slot-scope="scope">
            <rich-text @input="syncContent" :currentContent="content"></rich-text>
            <el-button type="primary" class="goodsindex-queryInfo-li" @click="confirm">确认</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane label="预览" name="2">
          <template slot-scope="scope">
            <div class="view" v-html="content" />
          </template>
        </el-tab-pane>
      </el-tabs>

      <el-dialog
          title="导入word"
          :visible.sync="openWord"
          width="40%">

          <el-form ref="editFormRef" :model="formData" label-width="180px">
            <el-form-item required label="Brand: " prop="brandId">
            <el-select v-model="formData.brandId" @change="selectBrand" clearable placeholder="请选择brand">
                <el-option
                v-for="item in brands"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>

          <el-form-item required label="升级包: " prop="file">
            <el-upload ref="addUploadRef"
                class="upload-demo"
                drag
                :limit="1"
                action="#"
                :file-list="fileList"
                :auto-upload="false"
                :on-change="handleChange">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传word文件(docx)，且不超过20M</div>
            </el-upload>
          </el-form-item>
          </el-form>

          <span slot="footer" class="dialog-footer">
            <el-button @click="openWord = false">取 消</el-button>
            <el-button type="primary" @click="handleConfirm()" :loading="loading">{{ confirmBtnTxt }}</el-button>
          </span>
        </el-dialog>

    </el-card>
  </div>
</template>

<script>
import RichText from './RichText.vue'
import Constant from '../components/Constant.vue'
import "@/assets/css/quillEditor.css"

export default {
  components: {RichText},

  data() {
    return {
      activeIndex: '1',
      brands: Constant.brands,
      id: '',
      brandId: '',
      content: '',
      openWord: false,
      fileList: [],
      formData: {
        brandId: '',
        file: ''
      },
      loading: false,
      confirmBtnTxt: '确 定'
    }
  },

  methods:{
    syncContent(content){
        console.log("内容:", content)
        this.content = content
    },

    selectBrand(brandId){
        console.log('当前选中brandId: ', brandId)
        this.brandId = brandId
        if(brandId) {
          this.$http.get('/app/help-guide?brandId=' + brandId)
          .then(res => {
            if(res.data) {
              console.log('查询返回: ', res.data)
              this.id = res.data.id
              this.content = res.data.content
            } else {
              this.content = ''
              this.id =  ''
            }
          })
        } else {
            this.content = ''
            this.id =  ''
        }
        

    },

    confirm(){
      let formData = {
        id: this.id,
        brandId: this.brandId,
        content: this.content
      }
      this.$http.post('/app/help-guide', formData)
      .then(res => {
        this.id = res.data
        this.$message.success('保存成功')
      })
    },

    tabClick() {
        console.log('当前选中: ', this.activeIndex)
    },

    uploadWord() {
      this.openWord = true
      this.formData.brandId = this.brandId
    },

    resetFormDatas() {
        this.$refs.editFormRef.resetFields()
        this.$refs.addUploadRef.clearFiles()
        this.formData = {}
    },

    handleChange(file, fileList) {
        if(file.size > 1024 * 1024 * 20) {
            this.$message.error('文件不得超过20M')
            fileList.pop()
            return;
        }
        if(file.status == 'ready') {
            if(fileList.length === 2) {
                fileList.shift()
            }
        }
        this.formData.file = file
    },

    handleRemove(file, fileList) {
        this.formData.file = ''
    },
    
    handleConfirm() {
      if(!this.formData.brandId) {
          this.$message.error("请选择Brand")
          return;
      }
      if(!this.formData.file) {
          this.$message.error("请选择word文件")
          return;
      }

      this.startLoading()

      const form = new FormData()
      form.append("id", this.id)
      form.append("brandId", this.formData.brandId)
      form.append("file", this.formData.file.raw)

      this.$http.post("/app/help-guide/word", form ,{
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }).then(res => {
          this.content = res.data.content
          this.id = res.data.id
          this.$message.success("导入成功")
          this.resetFormDatas()
          this.openWord = false
          this.endLoading()
      }).catch(() => {
        this.$message.error("导入失败!")
        this.endLoading()
      })
    },

    startLoading() {
      this.loading = true
      this.confirmBtnTxt = '上传中...'
    },

    endLoading() {
      this.loading = false
      this.confirmBtnTxt = '确 定'
    }

  }
}
</script>

<style scoped>
  .el-tabs {
    margin-top: 15px;
  }

  .el-button {
    margin-top: 15px;
  }

  .view{
    width: 100%;
    overflow: auto;
  }

  ::v-deep img{
      display:block; 
      width: 270px;
      max-width: 100%;
  }

  .uploadBtn {
    margin-left: 20px;
  }
</style>