<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>配置中心</el-breadcrumb-item>
        <el-breadcrumb-item>字典管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-row :gutter="20">
            <el-col :span="7">
              <el-input placeholder="请输入item" v-model="queryParams.item" clearable>
                <el-button slot="append" icon="el-icon-search"  @click="getDictionaryList()"></el-button>
              </el-input>
            </el-col>
            <el-col :span="4">
                <el-button type="primary" @click="addFormVisible = true">添加字典</el-button>
            </el-col>
        </el-row>

        <el-table :data="dictionaryPage.records" row-key="id" style="width: 100%" :header-cell-style="{background:'#F2F2F4', 'text-align':'center'}" border>
            <el-table-column type="index" label="编号" align="center"></el-table-column>
            <el-table-column prop="item" label="item名称" align="center">
            </el-table-column>
            <el-table-column prop="dataKey" label="字典key" align="center">
            </el-table-column>
            <el-table-column prop="dataValue" label="字典值" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center">
            </el-table-column>
            <el-table-column prop="updateTime" label="更新时间" align="center">
            </el-table-column>

            <el-table-column label="操作"  align="center">
              <template slot-scope="scope">
                <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
        </el-table>
        
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="dictionaryPage.pageNumber" :page-sizes="[1, 2, 5, 10]" :page-size="dictionaryPage.pageSize" 
          layout="total, sizes, prev, pager, next, jumper" :total="dictionaryPage.total">
        </el-pagination>

        <el-dialog
          title="新增"
          :visible.sync="addFormVisible"
          width="60%"
          @close="resetAddFormDatas">
            <el-form ref="addForm" :rules="formRules" :model="formData" label-width="80px">
              <el-form-item label="item: " prop="item">
                <el-input v-model="formData.item"></el-input>
              </el-form-item>
              <el-form-item label="字典key: " prop="dataKey">
                <el-input v-model="formData.dataKey"></el-input>
              </el-form-item>
              <el-form-item label="字典值: " prop="dataValue">
                <el-input type="textarea" autosize v-model="formData.dataValue"></el-input>
              </el-form-item>
              <el-form-item label="备注: " prop="remark">
                <el-input v-model="formData.remark"></el-input>
              </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
              <el-button @click="addFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="add()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
          title="编辑"
          :visible.sync="editFormVisible"
          width="60%"
          @close="resetEditFormDatas">
            
            <el-form ref="editForm" :rules="formRules" :model="formData" label-width="80px">
              <el-form-item label="item: " prop="item">
                <el-input v-model="formData.item"  @input="$forceUpdate()"></el-input>
              </el-form-item>
              <el-form-item label="字典key: " prop="dataKey">
                <el-input v-model="formData.dataKey"  @input="$forceUpdate()"></el-input>
              </el-form-item>
              <el-form-item label="字典值: " prop="dataValue">
                <el-input type="textarea" autosize v-model="formData.dataValue"  @input="$forceUpdate()"></el-input>
              </el-form-item>
              <el-form-item label="备注: " prop="remark">
                <el-input v-model="formData.remark"  @input="$forceUpdate()"></el-input>
              </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
              <el-button @click="editFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="edit()">确 定</el-button>
            </span>
        </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
        queryParams: {
          "pageNumber": 1,
          "pageSize": 10,
          "item": ''
        },
        dictionaryPage: {},
        addFormVisible: false,
        editFormVisible: false,
        formRules: {},
        formData: {
          "id": null,
          "item": '',
          "dataKey": '',
          "dataValue": ''
        }
    }
  },
  methods: {
    getDictionaryList() {
      this.$http.get('/dictionary', {
        params: this.queryParams
      })
      .then(res => {
        this.dictionaryPage = res.data
      })
    },
    handleEdit(row) {
        console.log('编辑字典', row)
        this.formData.id = row.id
        this.formData.item = row.item
        this.formData.dataKey = row.dataKey
        this.formData.dataValue = row.dataValue
        this.formData.remark = row.remark
        this.editFormVisible = true
    },
    handleDelete(id) {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.delete('/dictionary/'+id)
          .then(res => {
            this.$message.success('删除成功')
            this.getDictionaryList()
          })
        }).catch(() => {
          this.$message.info('取消删除')
        });
        
    },
    handleSizeChange(val) {
        this.queryParams.pageSize = val
        this.getDictionaryList()
    },
    handleCurrentChange(val) {
        this.queryParams.pageNumber = val
        this.getDictionaryList()
    },
    resetEditFormDatas() {
        this.$refs.editForm.resetFields()
        this.formData = {}
    },
    resetAddFormDatas() {
        this.$refs.addForm.resetFields()
        this.formData = {}
    },
    edit() {
        this.$http.post('/dictionary', this.formData)
        .then(res => {
            this.editFormVisible = false
            this.$message.success('编辑成功')
            this.getDictionaryList()
        })
    },
    add() {
        this.$http.post('/dictionary', this.formData)
        .then(res => {
            this.addFormVisible = false
            this.$message.success('添加成功')
            this.getDictionaryList()
        })
    }
  },
  created() {
    this.getDictionaryList()
  }
}
</script>

<style scoped>
  .el-input{
    width: 356px;
  }
</style>