import { render, staticRenderFns } from "./VersionList.vue?vue&type=template&id=1964bf92&scoped=true&"
import script from "./VersionList.vue?vue&type=script&lang=js&"
export * from "./VersionList.vue?vue&type=script&lang=js&"
import style0 from "./VersionList.vue?vue&type=style&index=0&id=1964bf92&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1964bf92",
  null
  
)

export default component.exports