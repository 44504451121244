<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>配置中心</el-breadcrumb-item>
        <el-breadcrumb-item>国际化</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-row :gutter="5">
            <el-col :xs="8" :sm="6" :md="6" :lg="4" :xl="4">
                <el-select v-model="queryParams.type" clearable placeholder="请选择类型">
                    <el-option
                    v-for="item in systems"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
             </el-col>

            <el-col :span="3">
                <el-input placeholder="请输入key" v-model="queryParams.key" clearable></el-input>
            </el-col>

            <el-col :span="3">
               <el-input placeholder="请输入Value" v-model="queryParams.value" clearable></el-input>
            </el-col>

            <el-col  :span="3">
                <el-button type="primary" class="goodsindex-queryInfo-li" size="small" @click="getList">搜索</el-button>
            </el-col>
            <el-col  :span="3">
                <el-button type="warning" class="goodsindex-queryInfo-li" size="small" @click="addFormVisible = true">新增</el-button>
            </el-col>
            <el-col  :span="3">
                <el-button type="success" plain class="goodsindex-queryInfo-li" size="mini" @click="exportData">导出Excel</el-button>
            </el-col>
            <el-col  :span="3">
                <el-button type="success" plain class="goodsindex-queryInfo-li" size="mini" @click="exportFile">导出文件</el-button>
            </el-col>
        </el-row>

        <el-table :data="resPage.records" row-key="id" 
        height="700"
        style="width: 100%" :header-cell-style="{background:'#F2F2F4', 'text-align':'center'}" border>
            <el-table-column prop="id" label="ID" align="center" width="60" fixed>
            </el-table-column>
            <el-table-column prop="typeDesc" label="类型" align="center" width="140" fixed>
            </el-table-column>
            <el-table-column prop="dataKey" label="KEY" align="center" width="140" fixed>
            </el-table-column>
 
            <el-table-column prop="enValue" label="英文" align="center" width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="csValue" label="捷克文" align="center" width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="deValue" label="德文" align="center" width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="frValue" label="法文" align="center" width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="itValue" label="意大利文" align="center" width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="jaValue" label="日文" align="center" width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="nlValue" label="荷兰文" align="center" width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="zhValue" label="中文" align="center" width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="ptValue" label="葡萄牙文" align="center" width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="esValue" label="西班牙文" align="center" width="200" show-overflow-tooltip>
            </el-table-column>

            <el-table-column prop="createdTimeString" label="创建时间" align="center" width="180">
            </el-table-column>
            <el-table-column prop="updatedTimeString" label="更新时间" align="center" width="180">
            </el-table-column>
            <el-table-column prop="updateUser" label="更新人" align="center">
            </el-table-column>
            <el-table-column label="操作"  align="center" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button type="text" size="mini" icon="el-icon-copy-document" @click="handleDelete(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="resPage.pageNumber" :page-sizes="[10, 20, 50]" :page-size="resPage.pageSize" 
            layout="total, sizes, prev, pager, next, jumper" :total="resPage.total">
        </el-pagination>

        <el-dialog
        :title="title"
        :visible.sync="addFormVisible"
        width="60%"
        @close="resetAddFormDatas"
        append-to-body>

            <el-form ref="addForm" :model="formData" label-width="100px">

                <el-form-item label="类型: " prop="type">
                    <el-select v-model="formData.type" clearable placeholder="请选择类型">
                        <el-option
                        v-for="item in systems"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item label="KEY: " prop="dataKey">
                    <el-input v-model="formData.dataKey" class="short-input" @input="$forceUpdate()" ></el-input>
                </el-form-item>
                
                <el-form-item label="英文: " prop="enValue">
                    <el-input type="textarea" :rows="2" v-model="formData.enValue" @input="$forceUpdate()" ></el-input>
                </el-form-item>

                <el-form-item label="捷克文: " prop="csValue">
                    <el-input type="textarea" :rows="2" v-model="formData.csValue" @input="$forceUpdate()" ></el-input>
                </el-form-item>

                <el-form-item label="德文: " prop="deValue">
                    <el-input type="textarea" :rows="2" v-model="formData.deValue" @input="$forceUpdate()" ></el-input>
                </el-form-item>

                <el-form-item label="法文: " prop="frValue">
                    <el-input type="textarea" :rows="2" v-model="formData.frValue" @input="$forceUpdate()" ></el-input>
                </el-form-item>

                <el-form-item label="意大利文: " prop="itValue">
                    <el-input type="textarea" :rows="2" v-model="formData.itValue" @input="$forceUpdate()" ></el-input>
                </el-form-item>

                <el-form-item label="日文: " prop="jaValue">
                    <el-input type="textarea" :rows="2" v-model="formData.jaValue" @input="$forceUpdate()" ></el-input>
                </el-form-item>

                <el-form-item label="荷兰文: " prop="nlValue">
                    <el-input type="textarea" :rows="2" v-model="formData.nlValue" @input="$forceUpdate()" ></el-input>
                </el-form-item>

                <el-form-item label="中文: " prop="zhValue">
                    <el-input type="textarea" :rows="2" v-model="formData.zhValue" @input="$forceUpdate()" ></el-input>
                </el-form-item>

                <el-form-item label="葡萄牙文: " prop="ptValue">
                    <el-input type="textarea" :rows="2" v-model="formData.ptValue" @input="$forceUpdate()" ></el-input>
                </el-form-item>

                <el-form-item label="西班牙文: " prop="esValue">
                    <el-input type="textarea" :rows="2" v-model="formData.esValue" @input="$forceUpdate()" ></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="resetAddFormDatas">取 消</el-button>
                <el-button type="primary" @click="add()">确 定</el-button>
            </span>

        </el-dialog>

        <el-dialog
        :title="exportFileTitle"
        :visible.sync="exportFormVisible"
        width="20%"
        @close="resetExportFormDatas"
        append-to-body>
            <el-form ref="exportForm" :model="exportFormData" label-width="100px">
                <el-form-item label="类型: " prop="os">
                    <el-select v-model="exportFormData.os" clearable placeholder="请选择类型">
                        <el-option
                        v-for="item in systems"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="语言: " prop="language">
                    <el-select v-model="exportFormData.language" clearable placeholder="请选择语言">
                        <el-option
                        v-for="item in languages"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="resetExportFormDatas">取 消</el-button>
                <el-button type="primary" @click="confirmExportFile()">确 定</el-button>
            </span>

        </el-dialog>
    </el-card>
  </div>
</template>

<script>
import Constant from '../components/Constant.vue'
import { download } from '../utils/download'

export default {
    data() {
        return {
            languages: Constant.languages,
            systems: Constant.systems,

            osList: [],

            queryParams: {
                lang: '',
                os: '',
                key: ''
            },

            resPage: {},

            title: '新增',

            addFormVisible: false,

            formData: {
            },

            exportFileTitle: '',
            exportFormVisible: false,
            exportFormData: {

            }
        }
    },

    methods: {
        getList() {
            console.log("查询条件:", this.queryParams)
            this.$http.get('/i18n-config', {
                params: this.queryParams
            }).then(res => {
                this.resPage = res.data
            })
        },

        handleEdit(row) {
            console.log("编辑:", row)
            this.title = "编辑"
            this.formData = {...row}
            
            this.addFormVisible = true
        },

        handleDelete(id) {
            console.log("删除:", id)
            this.$http.post('/i18n-config/del/'+id, {})
                .then(res => {
                    this.addFormVisible = false
                    this.$message.success('删除成功')
                    this.getList()
             })
        },

        handleSizeChange(val) {
            this.queryParams.pageSize = val
            this.getList()
        },

        handleCurrentChange(val) {
            this.queryParams.pageNumber = val
            this.getList()
        },

        resetAddFormDatas() {
            this.addFormVisible = false
            this.$refs.addForm.resetFields()
            this.formData = {}
        },

        add() {
            console.log("保存", this.formData)
            this.$http.post('/i18n-config', this.formData)
                .then(res => {
                    this.addFormVisible = false
                    this.$message.success('保存成功')
                    this.getList()
             })
        },

        // exportData() {
        //     download('/i18n-config/export', this.queryParams, '')
        // },

        exportData() {
            download('/bike/export-bikes', this.queryParams, '')
        },

        resetExportFormDatas() {
            this.exportFormData = {}
            this.exportFormVisible = false
        },

        exportFile() {
            this.exportFileTitle = "导出文件"
            this.exportFormVisible = true
        },

        confirmExportFile() {
            console.log("当前form: ", this.exportFormData)
            const language = this.exportFormData.language;
            if(!language) {
                this.$message.warning('请先选择语言')
                return;
            }
            const os = this.exportFormData.os;
            window.location.href = this.$apiBaseUrl + "/admin/i18n-config/export-file?language=" + language +"&os=" + os;
        }
    },

    created() {
        this.getList()
    }
}
</script>

<style scoped>
    .short-input{
        width: 356px;
    }

</style>