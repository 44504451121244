<template>
  
</template>

<script>
const brands = [
    {"label": 'VinkaLife', "value": 100},
    {"label": 'TOTEM', "value": 108},
    {"label": 'CNTD', "value": 109},
    {"label": 'NEOMOUV', "value": 111},
    {"label": 'Eraddar', "value": 113},
    {"label": 'Mixte', "value": 114},
    {"label": 'iGo', "value": 115},
    {"label": 'Vonovia', "value": 116},
    {"label": 'TCL', "value": 117},
] 

const systems = [
    {"label": 'android', "value": 1},
    {"label": 'iOS', "value": 2}
] 

const device_address = [
    {"label": 'Motor', "value": 1},
    {"label": 'Display', "value": 3},
    {"label": 'Battery', "value": 5},
    {"label": 'VBox-OpenCpu', "value": 6},
    {"label": 'VBox-BLE', "value": 7},
    {"label": 'VBox-GD', "value": 8}
] 

const city_types = [
    {"label": '省', "value": 1},
    {"label": '市', "value": 2},
    {"label": '区', "value": 3}
] 

const country_list = [
    {"label": '中国', "value": "中国"},
    {"label": '法国', "value": "France"},
    {"label": '德国', "value": "Germany"},
    {"label": '加拿大', "value": "Canada"},
    {"label": '捷克', "value": "Czechia"}
] 

const languages = [
    {"label": '英文', "value": "en"},
    {"label": '捷克文', "value": "cs"},
    {"label": '德文', "value": "de"},
    {"label": '法文', "value": "fr"},
    {"label": '意大利文', "value": "it"},
    {"label": '日文', "value": "ja"},
    {"label": '荷兰文', "value": "nl"},
    {"label": '中文', "value": "zh"},
    {"label": '葡萄牙文', "value": "pt"},
    {"label": '西班牙文', "value": "es"},
] 

const APP_LIST = [
    {"label": 'Vinka Life', "value": 1},
    {"label": 'Doctor', "value": 5},
    {"label": 'PDA APP', "value": 8},
] 

export default {
    brands, systems, APP_LIST, device_address, city_types, country_list, languages
}
</script>

<style>

</style>