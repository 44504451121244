<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>Doctor</el-breadcrumb-item>
        <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-row :gutter="25">
            <el-col  :xs="8" :sm="6" :md="6" :lg="4" :xl="4">
                <el-input placeholder="请输入用户名" v-model="queryParams.userName" clearable></el-input>
            </el-col>
            
            <el-col :xs="6" :sm="4" :md="3" :lg="2" :xl="2">
                <el-button type="primary" class="goodsindex-queryInfo-li" size="small" @click="getUserList">搜索</el-button>
            </el-col>
            <el-col :xs="6" :sm="4" :md="3" :lg="2" :xl="2">
                <el-button type="warning" class="goodsindex-queryInfo-li" size="small" @click="addFormVisible = true">创建账号</el-button>
            </el-col>
        </el-row>

        <el-table :data="userPage.records" row-key="userId" style="width: 100%" :header-cell-style="{background:'#F2F2F4', 'text-align':'center'}" border>
            <el-table-column type="index" align="center"></el-table-column>
            <el-table-column prop="userName" label="用户名称" width="180" align="center">
            </el-table-column>

            <el-table-column prop="createTime" label="创建时间" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" icon="el-icon-delete" @click="deleteUser(scope.row.userId)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="userPage.pageNumber" :page-sizes="[1, 2, 5, 10]" :page-size="userPage.pageSize" 
          layout="total, sizes, prev, pager, next, jumper" :total="userPage.total">
        </el-pagination>

        <el-dialog
          title="创建账号"
          :visible.sync="addFormVisible"
          width="30%"
          @close="resetAddFormDatas">
            <el-form ref="addForm" :model="formData" label-width="80px">
              <el-form-item label="用户名: " prop="userName" required>
                <el-input v-model="formData.userName" class="doc-input"></el-input>
              </el-form-item>

              <el-form-item label="Brand: " prop="brandId">
                <el-select v-model="formData.brandId" clearable placeholder="请选择brand">
                    <el-option
                    v-for="item in brands"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="密码: " prop="password">
                <el-input v-model="formData.password" class="doc-input" placeholder="默认: eBike6688"></el-input>
              </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
              <el-button @click="addFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="createAccount()">确 定</el-button>
            </span>
        </el-dialog>

    </el-card>
  </div>
</template>

<script>
import Constant from '../components/Constant.vue'

export default {
    data(){
        return {
            brands: Constant.brands,

            queryParams: {},

            addFormVisible: false,

            userPage: {},

            formData: {
                "userName": '',
                "password": ''
            }
        }
    },

    methods: {
        getUserList() {
             this.$http.get('/doctor/user', {
                params: this.queryParams
            }).then(res => {
                this.userPage = res.data
            })
        },

        handleSizeChange(){
            this.queryParams.pageSize = val
            this.getUserList()
        },

        handleCurrentChange() {
            this.queryParams.pageNumber = val
            this.getUserList()
        },

        deleteUser(userId) {
            console.log("删除用户:", userId)
            this.$http.post('/doctor/user/del/'+userId, {})
                .then(res => {
                    this.addFormVisible = false
                    this.$message.success('删除账号成功')
                    this.getUserList()
             })
        },

        createAccount() {
            this.formData.registerSource = 1
            this.$http.post('/doctor/user', this.formData)
                .then(res => {
                    this.addFormVisible = false
                    this.$message.success('账号创建成功')
                    this.getUserList()
             })
        },

        resetAddFormDatas() {
            this.$refs.addForm.resetFields()
        },
    },
    created() {
        this.getUserList()
    }
}
</script>

<style scoped>
    .doc-input{
        width: 80%;
    }
</style>