<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-row :gutter="25">
            <el-col  :xs="8" :sm="6" :md="6" :lg="4" :xl="4">
                <el-input placeholder="请输入用户名" v-model="queryParams.userName" clearable></el-input>
            </el-col>
            <el-col  :xs="8" :sm="6" :md="6" :lg="4" :xl="4">
               <el-input placeholder="请输入邮箱" v-model="queryParams.email" clearable></el-input>
            </el-col>
            <el-col :xs="6" :sm="4" :md="3" :lg="2" :xl="2">
                <el-button type="primary" class="goodsindex-queryInfo-li" size="small" @click="getUserList">搜索</el-button>
            </el-col>
            <el-col :xs="6" :sm="4" :md="3" :lg="2" :xl="2">
                <el-tooltip class="item" effect="dark" content="只需email即可创建账号" placement="top-start">
                    <el-button type="warning" class="goodsindex-queryInfo-li" size="small" @click="addFormVisible = true">快速创建账号</el-button>
                </el-tooltip>
            </el-col>
        </el-row>

        <el-table :data="userPage.records" row-key="userId" style="width: 100%" :header-cell-style="{background:'#F2F2F4', 'text-align':'center'}" border>
            <el-table-column type="index" align="center"></el-table-column>
            <el-table-column prop="userName" label="用户名称" width="180" align="center">
            </el-table-column>
            <el-table-column prop="email" label="邮箱" width="180" align="center">
            </el-table-column>
            <el-table-column prop="userType" label="用户类型" align="center">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.userType === 1">游客</el-tag>
                    <el-tag size="mini" type="success" v-if="scope.row.userType === 2">注册用户</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="brandName" label="用户brand" align="center">
            </el-table-column>
            <el-table-column prop="registerSource" label="注册方式" align="center">
            </el-table-column>
            <el-table-column prop="createTime" label="注册时间" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" icon="el-icon-bicycle" @click="createTmpBike(scope.row.userId)">绑车</el-button>
                    <el-button type="text" size="mini" icon="el-icon-location-information" @click="showPosition(scope.row.userId)">位置</el-button>
                    <el-button type="text" size="mini" icon="el-icon-s-operation" @click="editPrivilege(scope.row.userId)">权限</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="userPage.pageNumber" :page-sizes="[1, 2, 5, 10]" :page-size="userPage.pageSize" 
          layout="total, sizes, prev, pager, next, jumper" :total="userPage.total">
        </el-pagination>

        <el-dialog
          title="新增账号"
          :visible.sync="addFormVisible"
          width="40%"
          @close="resetAddFormDatas">
            <el-form ref="addForm" :model="formData" label-width="100px">
              <el-form-item label="User Name: " prop="userName">
                <el-input v-model="formData.userName" class="dia-input"></el-input>
              </el-form-item>
              <el-form-item label="email: " prop="email" required>
                <el-input v-model="formData.email" class="dia-input"></el-input>
              </el-form-item>
              <el-form-item label="Password: " prop="password">
                <el-input v-model="formData.password" class="dia-input"></el-input>
              </el-form-item>

              <el-form-item label="Brand: " prop="brandId">
                <el-select v-model="formData.brandId" clearable placeholder="请选择brand">
                    <el-option
                    v-for="item in brands"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
              <el-button @click="addFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="add()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
          title="用户绑定车辆"
          :visible.sync="addBikeFormVisible"
          width="40%"
          @close="resetAddBikeFormDatas">
          <el-alert title="若VBox尚未绑定车辆 ,将创建车辆" type="warning"  left show-icon :closable="false"></el-alert>
            <el-form ref="addBikeForm" :model="bikeFormData" label-width="80px">
              <el-form-item label="vBox imei: " prop="imei">
                <el-input v-model="bikeFormData.boxInfo.imei" class="dia-input"></el-input>
              </el-form-item>
              <el-form-item label="车辆名称: " prop="bikeName">
                <el-input v-model="bikeFormData.bikeName" class="dia-input"></el-input>
              </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
              <el-button @click="addBikeFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="addBike()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
          title="位置信息"
          :visible.sync="positionVisible"
          width="40%">
            <el-descriptions class="margin-top" title="用户位置" :column="3">
                <el-descriptions-item label="用户名">{{position.userName}}</el-descriptions-item>
                <el-descriptions-item label="经度">{{position.gpsLongitude}}</el-descriptions-item>
                <el-descriptions-item label="纬度">{{position.gpsLatitude}}</el-descriptions-item>
                <el-descriptions-item label="上报时间">{{position.gpsTime}}</el-descriptions-item>
            </el-descriptions>

            <el-alert class="positionAlert" title="车辆位置" type="success" :closable="false"> </el-alert>

            <el-table :data="position.bikePositions" row-key="bikeId" style="width: 100%" :header-cell-style="{background:'#F2F2F4', 'text-align':'center'}" border>
              <el-table-column prop="bikeName" label="车辆名" align="center">
              </el-table-column>
              <el-table-column prop="gpsLongitude" label="经度" align="center">
              </el-table-column>
              <el-table-column prop="gpsLatitude" label="纬度" align="center">
              </el-table-column>
              <el-table-column prop="gpsTime" label="上报时间" align="center">
              </el-table-column>
	        </el-table>
        </el-dialog>

        <el-dialog
          title="权限开关"
          :visible.sync="privilegeVisible"
          width="40%"
          @close="resetPrivilegeForm">
          
            <el-form ref="privilegeForm" :model="privilegeForm" label-width="80px">
              <el-form-item label="导航开关: " prop="navSwitch">
                <el-switch v-model="privilegeForm.navSwitch" @change="changeNavSwitch"></el-switch>
              </el-form-item>
            </el-form>
            
        </el-dialog>
    </el-card>
  </div>
</template>

<script>
import Constant from '../components/Constant.vue'

export default {
    data() {
        return {
            brands: Constant.brands,

            queryParams: {
                userName: "",
                email: "",
                brandId: null,
                pageNumber: 1,
                pageSize: 10
            },
            userPage: {},
            addFormVisible: false,
            formData: {},

            addBikeFormVisible: false,
            bikeFormData: {
                'bikeName': '',
                'boxInfo': {
                    'imei': ''
                }
            },

            userId: '',
            positionVisible: false,
            position: [],

            privilegeVisible: false,
            privilegeForm: {
                navSwitch: false
            }
        }
    },

    methods: {
        getUserList() {
            this.$http.get('/user', {
                params: this.queryParams
            }).then(res => {
                this.userPage = res.data
            })
        },
        createTmpBike(userId) {
            this.bikeFormData.userId = userId
            this.addBikeFormVisible = true
        },
        handleSizeChange(val) {
            this.queryParams.pageSize = val
            this.getUserList()
        },
        handleCurrentChange(val) {
            this.queryParams.pageNumber = val
            this.getUserList()
        },
        resetAddFormDatas() {
            this.$refs.addForm.resetFields()
        },
        resetAddBikeFormDatas() {
            this.$refs.addBikeForm.resetFields()
            this.bikeFormData.boxInfo.imei = ''
        },
        add() {
            console.log("新增用户:", this.formData.email+ '-' + this.formData.brandId)

             this.$http.post('/user/account', this.formData)
                .then(res => {
                    this.addFormVisible = false
                    this.$message.success('添加成功, 默认密码是: Vinka8688')
                    this.getUserList()
             })
        },
        addBike() {
            this.$http.post('/bike/bind', this.bikeFormData)
                .then(res => {
                    this.addBikeFormVisible = false
                    this.$message.success('绑定成功')
             })
        },

        showPosition(userId) {
            this.userId = userId
            this.positionVisible = true
            this.$http.get('/user/position/'+userId)
            .then(res => {
                this.position = res.data
            })
        },

        editPrivilege(userId) {
            this.userId = userId
            this.privilegeVisible = true
            this.$http.get('/user/privileges/'+userId)
            .then(res => {
                this.privilegeForm.navSwitch = res.data.navPrivilege
            })
        },

        resetPrivilegeForm() {

        },

        changeNavSwitch(newValue) {
            console.log("导航开关:", newValue)
            const navParam = {
                type: 1,
                userId: this.userId
            }
            //开启权限
            if(newValue) {
                this.$http.post('/app/nav-privileges', navParam)
                .then(res => {
                    this.$message.success('成功开启APP导航权限')
                })
            } else {
                //关闭权限
                this.$http.post('/app/close-nav-privileges', navParam)
                .then(res => {
                    this.$message.success('成功关闭APP导航权限')
                })
            }
            
        }
    },
    created() {
        this.getUserList()
    }
}
</script>

<style scoped>
    .el-form{
        margin-top: 10px;
    }

    .positionAlert{
        margin-top: 20px;
    }
</style>