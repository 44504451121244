<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
        <el-breadcrumb-item>VBox列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-row :gutter="20">
            <el-col :span="7">
              <el-input placeholder="请输入imei" v-model="queryParams.imei" clearable>
                <el-button slot="append" icon="el-icon-search"  @click="getVboxList()"></el-button>
              </el-input>
            </el-col>
        </el-row>

        <el-table :data="vboxPage.records" row-key="id" style="width: 100%" :header-cell-style="{background:'#F2F2F4', 'text-align':'center'}" border>
            <el-table-column type="index" label="编号" align="center"></el-table-column>
            <el-table-column prop="imei" label="imei" align="center">
            </el-table-column>
            <el-table-column prop="iccid" label="iccid" align="center">
            </el-table-column>
            <el-table-column prop="openCpuVersion" label="当前版本" align="center">
            </el-table-column>
            <el-table-column prop="versionId" label="型号" align="center">
            </el-table-column>
            <el-table-column prop="online" label="状态" align="center">
                <template slot-scope="scope">
                    <el-tag size="mini" type="success" v-if="scope.row.online">在线</el-tag>
                    <el-tag size="mini" type="info" v-else>离线</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="activeTime" label="激活时间" align="center">
            </el-table-column>

            <el-table-column label="操作"  align="center">
              <template slot-scope="scope">
                <el-button type="text" size="mini" icon="el-icon-s-tools" @click="simnulate(scope.row)">模拟</el-button>
                <el-button type="text" size="mini" icon="el-icon-delete" @click="clearCache(scope.row.imei)">清空缓存</el-button>
                <el-button type="text" size="mini" icon="el-icon-edit" @click="openChangeBike(scope.row.imei)">换绑车辆</el-button>
                <el-button type="text" size="mini" icon="el-icon-edit" @click="openChangeEnv(scope.row.imei)">切换环境</el-button>
              </template>
            </el-table-column>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="vboxPage.pageNumber" :page-sizes="[1, 2, 5, 10]" :page-size="vboxPage.pageSize" 
          layout="total, sizes, prev, pager, next, jumper" :total="vboxPage.total">
        </el-pagination>

        <el-dialog
          title="VBox换绑车辆"
          :visible.sync="changeBikeVisible"
          width="40%"
          @close="resetChangeBikeFormDatas">
          <el-alert title="将VBox换绑到指定车辆下" type="warning"  left show-icon :closable="false"></el-alert>
            <el-form ref="changeBikeFormRef" :model="changeBikeForm" label-width="80px">
              <el-form-item label="BikeID: " prop="bikeId">
                <el-input v-model="changeBikeForm.bikeId" class="dia-input"></el-input>
              </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
              <el-button @click="changeBikeVisible = false">取 消</el-button>
              <el-button type="primary" @click="changeBike()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
          title="切换VBox连接环境"
          :visible.sync="changeEnvVisible"
          width="40%"
          @close="resetChangeEnvFormDatas">
            <el-form ref="changeEnvFormRef" :model="changeBikeForm" label-width="80px">
              <el-form-item label="IMEI: " prop="imei">
                <el-input readonly v-model="changeEnvForm.imei" class="dia-input"></el-input>
              </el-form-item>

              <el-form-item label="环境: " prop="envKey">
                <el-select v-model="changeEnvForm.envKey" clearable placeholder="请选择切换的环境">
                    <el-option
                    v-for="item in envs"
                    :key="item.key"
                    :label="item.key"
                    :value="item.key">
                    </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
              <el-button @click="changeEnvVisible = false">取 消</el-button>
              <el-button type="primary" :loading="loading" @click="changeEnv()">{{ envBtnText }}</el-button>
            </span>
        </el-dialog>
    </el-card>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js/crypto-js'

export default {
    data() {
        return {
            queryParams: {},
            changeBikeForm: {},
            changeBikeVisible: false,
            vboxPage: {},
            envs: [],
            changeEnvVisible: false,
            changeEnvForm: {},
            loading: false,
            intervalId: '',
            envBtnText: '确 定',
            cmdMessageId: ''
        }
    },

    methods: {
        getVboxList() {
            this.$http.get('/bike/vBox-page', {
                params: this.queryParams
            })
            .then(res => {
                this.vboxPage = res.data
            })
        },
        handleSizeChange(val) {
            this.queryParams.pageSize = val
            this.getVboxList()
        },
        handleCurrentChange(val) {
            this.queryParams.pageNumber = val
            this.getVboxList()
        },

        simnulate(row) {
            console.log('row: ', row)
            this.$router.push({name: 'vboxSimulate', params: {'imei': row.imei, 'bikeId': row.bikeId}})
        },

        clearCache(imei) {
            this.$http.delete('/bike/clear-cache/'+imei)
            .then(res => {
                this.$message.success('缓存已清理')
            })
        },
        resetChangeBikeFormDatas() {
            this.$refs.changeBikeFormRef.resetFields()
            this.changeBikeForm.bikeId = ''
        },

        openChangeBike(imei) {
            this.changeBikeForm.imei = imei
            this.changeBikeVisible = true
        },

        changeBike() {
            this.$http.post("/bike/vbox/change-bind", this.changeBikeForm)
            .then(res => {
                this.$message.success('换绑成功')
                this.getVboxList()
                this.changeBikeVisible = false
            })
        },

        initEnvs() {
            this.$http.get('/bike/vbox/envs', {})
            .then(res => {
                this.envs = res.data
            })
        },

        openChangeEnv(imei) {
            this.changeEnvForm.imei = imei
            this.changeEnvVisible = true
        },

        resetChangeEnvFormDatas() {
            this.$refs.changeEnvFormRef.resetFields()
            this.changeEnvForm.envKey = ''   
        },

        changeEnv() {
            this.$http.post("/bike/vbox/switch-env", this.changeEnvForm)
            .then(res => {
                if(res.code == '10200') {
                     const msgId = res.data
                     console.log("命令返回:", msgId)
                    if(msgId == '-1') {
                        this.$message.error('VBox已离线, 切换失败')
                    } else {
                        this.cmdMessageId = msgId
                        this.startLoading()
                        this.requestEnvResult()
                    }
                }
               
            })
        },

        startLoading() {
            this.loading = true
            this.envBtnText = '等待VBox返回结果...'
        },

        endLoading() {
            this.loading = false
            this.envBtnText = "确 定"
        },

        requestEnvResult() {
            //已轮训的次数
            let intervalIimes = 0;

            this.intervalId = setInterval(() => {
                intervalIimes++;
                if (intervalIimes > 60) {
                    this.$message.warning("VBox无响应")
                    this.endLoading()
                    clearInterval(this.intervalId);
                    return;
                }
                this.fetchEnvSwitchResult();
            }, 1000);
        },

        fetchEnvSwitchResult() {
            console.log("查询命令结果, msgId:", this.cmdMessageId)
             this.$http.get('/bike/vbox/cmd-result', {
                params: {messageId: this.cmdMessageId}
             }).then(res => {
                const result = res.data.cmdResult
                if(result === 1) {
                    this.$message.success("切换成功!")
                    clearInterval(this.intervalId);
                    this.endLoading()
                    return;
                }
                if(result === 0) {
                    this.$message.error("切换失败!")
                    clearInterval(this.intervalId);
                    this.endLoading()
                    return;
                }
            })

        },
    },

    created() {
        this.getVboxList()
        this.initEnvs()
    }
}
</script>