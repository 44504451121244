<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
        <el-breadcrumb-item>VBox模拟测试</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <!-- <el-alert title="请谨慎使用！" type="info" center show-icon :closable="false"></el-alert> -->

      <el-alert :title="'imei: ' + vBoxInfo.imei" type="success" :closable="false"> </el-alert>

      <el-tabs type="border-card" v-model="activeIndex" @tab-click="tabClick">
        <el-tab-pane label="电机偷盗推送" name="1">
          <template slot-scope="scope">
            <el-button type="warning" @click="simulate">一键模拟偷盗</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane label="低电量推送" name="2">
          <template slot-scope="scope">
            <el-button type="warning" @click="simulate">一键模拟低电量</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane label="陀螺仪偷盗推送" name="3">
          <template slot-scope="scope">
            <el-button type="warning" @click="simulate">一键模拟陀螺仪偷盗</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane label="摔倒SOS" name="4">
          <template slot-scope="scope">
            <el-button type="warning" @click="simulate">一键模拟摔倒SOS</el-button>
          </template>
        </el-tab-pane>
         <el-tab-pane label="Gps定位" name="5">
          <template slot-scope="scope">
            <el-row>
               <el-form ref="gpsForm" :model="gpsFormData" label-width="80px">
                  <el-form-item label="经度: " prop="lon">
                    <el-input v-model="gpsFormData.lon" style="width: 365px"></el-input>
                  </el-form-item>
                  <el-form-item label="纬度: " prop="lat">
                    <el-input v-model="gpsFormData.lat" style="width: 365px"></el-input>
                  </el-form-item>
               </el-form>
            </el-row>
            <el-row>
              <el-button type="warning" @click="simulate">确认Gps定位</el-button>
            </el-row>
          </template>
        </el-tab-pane>
      </el-tabs>

      <el-alert title="VBox关联用户如下: " type="warning"  center show-icon :closable="false"></el-alert>
        <el-table :data="targetUsers" row-key="userId" style="width: 100%" :header-cell-style="{background:'#F2F2F4', 'text-align':'center'}" border>
              <el-table-column type="index" label="编号" align="center"></el-table-column>
              <el-table-column prop="userId" label="用户ID" align="center">
              </el-table-column>
              <el-table-column prop="userName" label="用户名" align="center">
              </el-table-column>
              
              <el-table-column prop="bikeNotiFlag" label="接收消息开关" align="center">
                  <template slot-scope="scope">
                      <el-tag size="mini" type="success" v-if="scope.row.bikeNotiFlag === 1">开启</el-tag>
                      <el-tag size="mini" type="info" v-else>关闭</el-tag>
                  </template>
              </el-table-column>
              <el-table-column prop="owner" label="用户身份" align="center">
                  <template slot-scope="scope">
                      <el-tag size="mini" type="success" v-if="scope.row.owner">车主</el-tag>
                      <el-tag size="mini" type="info" v-else>非车主</el-tag>
                  </template>
              </el-table-column>
      </el-table>
      
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vBoxInfo: {},
      activeIndex: "1",

      targetUsers: [],
      gpsFormData: {}
    }
  },

  methods: {
      tabClick() {
          console.log('当前选中: ', this.activeIndex)
      },

      getRelatedUsers() {
        this.$http.get('/bike/targetUsers', {
          params: {'imei': this.vBoxInfo.imei, 'type': this.activeIndex}
        })
        .then(res => {
          this.targetUsers = res.data
        })
      },

      simulate() {
        //设置定位
        if(this.activeIndex == 5) {
          this.$http.post('/debug/vbox-gps', 
          {
            'bikeId': this.vBoxInfo.bikeId, 
            'lon': this.gpsFormData.lon,
            'lat': this.gpsFormData.lat
          }).then(res => {
            this.$message.success('设置Gps定位成功')
          })
          return;
        }

        this.$http.post('/bike/simulate', 
          {
            'imei': this.vBoxInfo.imei, 
            'type': this.activeIndex
          }).then(res => {
          this.$message.success('模拟成功')
        })
      }
  },

  created() {
    console.log('VBox信息:', this.$route.params)
    this.vBoxInfo = this.$route.params
    this.getRelatedUsers()
  }
}
</script>

<style scoped>
  .el-tabs{
    margin-top: 15px;
  }
  .el-alert{
    margin-top: 10px;
  }
</style>