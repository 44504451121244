<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
        <el-breadcrumb-item>版本中心</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-row :gutter="5">
             <el-col :xs="8" :sm="6" :md="6" :lg="4" :xl="4">
                <el-cascader
                placeholder="选择设备/型号"
                filterable
                clearable
                v-model="versionId"
                :options="options"
                :props="{ expandTrigger: 'hover' }"
                @change="handleQueryChange"></el-cascader>
             </el-col>
             <el-col :xs="8" :sm="6" :md="6" :lg="4" :xl="4">
                <el-select v-model="queryParams.latest" clearable placeholder="最新版本">
                    <el-option :key="1" label="是" :value="1"></el-option>
                    <el-option :key="0" label="否" :value="0"></el-option>
                </el-select>
            </el-col>
            <el-col :xs="6" :sm="4" :md="3" :lg="2" :xl="2">
                <el-button type="primary" class="goodsindex-queryInfo-li" size="small" @click="getVersionList">搜索</el-button>
            </el-col>

            <el-col :xs="6" :sm="4" :md="3" :lg="2" :xl="2">
                <el-tooltip class="item" effect="dark" content="上传新版本" placement="top-start">
                    <el-button type="warning" class="goodsindex-queryInfo-li" size="small" @click="handleAdd">发布新版本</el-button>
                </el-tooltip>
            </el-col>
        </el-row>

        <el-table :data="versionPage.records" row-key="id" style="width: 100%" :header-cell-style="{background:'#F2F2F4', 'text-align':'center'}" border>
                <el-table-column type="index" label="编号" align="center"></el-table-column>
            <el-table-column prop="categoryName" label="设备类型" align="center"  width="100px">
            </el-table-column>
            <el-table-column prop="versionId" label="型号" show-overflow-tooltip align="center" width="100px">
            </el-table-column>
            <el-table-column prop="versionNum" label="版本号" align="center" width="90px">
            </el-table-column>
             <el-table-column prop="packageUrl" label="升级包" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="publishDate" label="发布时间" width="100px" align="center">
            </el-table-column>
           <el-table-column prop="packageReleaseNote" label="Release Note" align="center">
            </el-table-column>
            <el-table-column prop="latest" label="最新版" align="center" width="80px">
                <template slot-scope="scope">
                    <el-tag size="mini" type="success" v-if="scope.row.latest">是</el-tag>
                    <el-tag size="mini" type="info" v-else>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="versionStatus" label="状态" align="center"  width="80px">
                <template slot-scope="scope">
                    <el-tag size="mini" type="success" v-if="scope.row.versionStatus === 1">已发布</el-tag>
                    <el-tag size="mini" type="warning" v-else>待发布</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="操作"  align="center">
              <template slot-scope="scope">
                <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button type="text" v-show="scope.row.versionStatus === 0" size="mini" icon="el-icon-check" @click="handleDeploy(scope.row.id)">发布</el-button>
                <el-button type="text" v-show="scope.row.status === 1" size="mini" icon="el-icon-arrow-down" @click="handleUnDeploy(scope.row.id)">下架</el-button>
                <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="versionPage.pageNumber" :page-sizes="[1, 2, 5, 10]" :page-size="versionPage.pageSize" 
            layout="total, sizes, prev, pager, next, jumper" :total="versionPage.total">
        </el-pagination>

        <el-dialog
          :title="title"
          :visible.sync="formVisible"
          width="60%"
          @close="resetFormDatas">
            <el-form ref="formRef" :model="formData" label-width="80px">
                <el-form-item label="设备/型号: " prop="formVersionId">
                    <el-cascader
                    ref="formVersionId"
                    placeholder="选择设备/型号"
                    filterable
                    clearable
                    v-model="formVersionId"
                    :options="options"
                    :props="{ expandTrigger: 'hover' }"
                    @change="handleFormChange"></el-cascader>
                </el-form-item>

                <el-form-item label="版本号: " prop="versionNum">
                    <el-input v-model="formData.versionNum" @input="$forceUpdate()" class="dia-input"></el-input>
                </el-form-item>

                <el-form-item label="发布信息: " prop="releaseNote">
                    <el-input type="textarea" :rows="4" v-model="formData.releaseNote" @input="$forceUpdate()"></el-input>
                </el-form-item>

                <el-form-item label="升级包: " prop="packageUrl">
                    <el-upload ref="addUploadRef"
                        class="upload-demo"
                        drag
                        action="#"
                        :auto-upload="false"
                        :on-remove="handleRemove"
                        :on-change="handleChange"
                        :file-list="fileList"
                        >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传升级包，且不超过10M</div>
                    </el-upload>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
              <el-button @click="resetFormDatas">取 消</el-button>
              <el-button type="primary" @click="submitVersion">确 定</el-button>
            </span>
        </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
    data() {
        return {
            versionId: [],
            formVersionId: [],
            options: [],
            formVisible: false,
            versionPage: {},
            queryParams: {},
            title: '',
            formData: {},
            fileList: []
        }
    },

    methods: {
        loadOptions() {
            this.$http.get('/bike/version/category-options')
            .then(res => {
                this.options = res.data
            })
        },

        getVersionList() {
            if(this.versionId.length == 2) {
                this.queryParams.packageId = this.versionId[1]
            }

            this.$http.get('/bike/version', {params: this.queryParams})
            .then(res => {
                this.versionPage = res.data
            })
        },

        addVersion() {
             this.$http.post('/app/version', this.formData)
            .then(res => {
                this.addFormVisible = false
                this.$message.success('添加成功')
                this.getVersionList()
            })
        },

        handleAdd() {
            this.title = "新增"
            this.formVisible = true
        },

        handleEdit(row) {
            this.title = "编辑"
            this.formVisible = true
            this.formData.id = row.id
            console.log("编辑数据:", row)
            this.formData.id = row.id
            this.formData.versionNum = row.versionNum
            this.formData.packageId = row.packageId
            this.formData.releaseNote = row.packageReleaseNote
            this.formVersionId = [row.packageCategory + '', row.packageId]
            console.log("编辑级联选择数据:", [row.packageCategory, row.packageId])
        },

        handleDeploy(id) {
            this.$http.get('/bike/version/deploy/'+id)
            .then(res => {
                this.$message.success('发布成功')
                this.getVersionList()
            })
        },

        handleUnDeploy(id) {
            this.$http.get('/bike/version/undeploy/'+id)
            .then(res => {
                this.$message.success('下架成功')
                this.getVersionList()
            })
        },

        handleDelete(id) {
            this.$http.delete('/bike/version/'+id)
            .then(res => {
                this.$message.success('删除成功')
                this.getVersionList()
            })
        },

        handleSizeChange(val) {
            this.queryParams.pageSize = val
            this.getVersionList()
        },

        handleCurrentChange(val) {
            this.queryParams.pageNumber = val
            this.getVersionList()
        },

        resetFormDatas() {
            this.$refs.formRef.resetFields()
            this.$refs.addUploadRef.clearFiles()
            this.formData = {}
            this.formVersionId = ''
            this.formVisible = false
        },

        handleRemove(file, fileList) {
            this.formData.multipartFile = ''
        },

        handleChange(file, fileList) {
            if(file.size > 1024 * 1024 * 10) {
                this.$message.error('升级包最大10M')
                fileList.pop()
                return;
            }
            if(file.status == 'ready') {
                if(fileList.length === 2) {
                    fileList.shift()
                }
            }
            this.formData.multipartFile = file.raw
        },

        submitVersion() {
            if(this.formVersionId.length == 2) {
                this.formData.packageId = this.formVersionId[1]
            }
            this.$http.post("/bike/version", this.formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            )
            .then(res => {
                this.$message.success("保存成功")
                this.resetFormDatas()
                this.formVisible = false
                this.getVersionList()
            })
        },

        handleFormChange(value) {
            console.log("选择Form级联:", value)
            if(value.length === 0) {
                this.formData.packageId = ''
            }
        },

        handleQueryChange(value) {
            if(value.length === 0) {
                this.queryParams.packageId = ''
            }
        }
    },

    created() {
        this.loadOptions()
        this.getVersionList()
    }
}
</script>

<style scoped>
    .el-cascader{
        margin-top: 0;
    }
</style>