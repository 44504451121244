import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
import axios from 'axios'
import { Message } from 'element-ui'
//富文本
import  VueQuillEditor from 'vue-quill-editor'//引入富文本编辑器编辑
Vue.use(VueQuillEditor)
import imageResize  from 'quill-image-resize-module' // 调整大小组件。
import { ImageDrop } from 'quill-image-drop-module'; // 拖动加载图片组件。
Quill.register('modules/imageResize', imageResize );
Quill.register('modules/imageDrop', ImageDrop);
//当前环境
const env = "test";

if(env == "local") {
  axios.defaults.baseURL = "http://localhost:8060/api/admin/"
  Vue.prototype.$apiBaseUrl = "http://localhost:8060/api/"
} else if(env == "test") {
  axios.defaults.baseURL = "https://ebike-api-dev.vinkacloud.com/api/admin/"
  Vue.prototype.$apiBaseUrl = "https://ebike-api-dev.vinkacloud.com/api/"
} else if(env == "pro") { 
  axios.defaults.baseURL = "https://ebike-api.vinkacloud.com/api/admin/"
  Vue.prototype.$apiBaseUrl = "https://ebike-api.vinkacloud.com/api/"
} else {
  axios.defaults.baseURL = "https://ebike-api-dev.vinkacloud.com/api/admin/"
  Vue.prototype.$apiBaseUrl = "https://ebike-api-dev.vinkacloud.com/api/"
}

//全局环境参数
Vue.prototype.$envFlag = env

//为axios添加拦截器,设置头消息
axios.interceptors.request.use(config =>{
  config.headers.accessToken = window.sessionStorage.getItem('accessToken');
  return config;//最后必须返回config
})

axios.interceptors.response.use(response =>{
    // 二进制数据则直接返回
    if(response.request.responseType ===  'blob' || response.request.responseType ===  'arraybuffer'){
      return response
    }

  const code = response.data.code
  if(code === 10200) {
    return response.data
  }
  if(code === 10402) {
    router.push("/login")
    Message.warning('登录失效, 请重新登录')
  }
  if(code === 10400) {
    console.log('返回参数: ', response)
    Message.error(response.data.message)
  }
  if(code === 10500) {
    Message.error(response.data.message)
  }
  return Promise.reject(response.data)
})

Vue.config.productionTip = true
Vue.prototype.$http = axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
