<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
        <el-breadcrumb-item>车辆信息</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
        <el-row :gutter="25">
            
            <el-col :xs="6" :sm="4" :md="3" :lg="2" :xl="2">
              <el-button type="warning" class="goodsindex-queryInfo-li" size="small" @click="exportSims">导出</el-button>
            </el-col>
        </el-row>
        </el-card>

  </div>
</template>

<script>
export default {
  methods: {
        exportSims() {
          this.$http.post('/bike/export-bikes', {})
                .then(res => {
                    this.$message.success('成功')
             })
        },
      }
}
</script>

<style>

</style>