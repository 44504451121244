import { render, staticRenderFns } from "./RichText.vue?vue&type=template&id=0cdc74e3&scoped=true&"
import script from "./RichText.vue?vue&type=script&lang=js&"
export * from "./RichText.vue?vue&type=script&lang=js&"
import style0 from "./RichText.vue?vue&type=style&index=0&id=0cdc74e3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cdc74e3",
  null
  
)

export default component.exports