<template>
  <div>
    <!-- 图片上传组件辅助-->
    <el-upload
      class="avatar-uploader"
      :action="serverUrl"
      name="file"
      :headers="header"
      :show-file-list="false"
      :on-success="uploadSuccess"
      :on-error="uploadError"
      :before-upload="beforeUpload"
      v-show="false"
    >
    </el-upload>

    <quill-editor
    class="editor"
    v-model="content"
    ref="myQuillEditor"
    :options="editorOption"
    @blur="onEditorBlur($event)"
    @focus="onEditorFocus($event)"
    @change="onEditorChange($event)"
    >
    </quill-editor>
    <!-- <div class="ql-container ql-snow">
        <div class="content ql-editor" v-html="content" />
    </div> -->
  </div>
</template>

<script>
// 工具栏配置
var fonts = [ 'Microsoft-YaHei', 'SF-Pro-Text','PingFangSC','SimHei','SimSun', 'KaiTi', 'FangSong', 'Arial', 'sans-serif']

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
  ["blockquote", "code-block"], // 引用  代码块
  [{ header: 1 }, { header: 2 }], // 1、2 级标题
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
  [{ script: "sub" }, { script: "super" }], // 上标/下标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进
  [{ direction: "rtl" }], // 文本方向
  [{size: []}], // 字体大小
  //[{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
   [{ header: [] }], // 标题
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
  [{ font: fonts }], // 字体种类
  [{ align: [] }], // 对齐方式
  ["clean"], // 清除文本格式
  //   ["link", "image","video"] // 链接、图片、视频
  ["link","image"], // 链接、图片
];

import { quillEditor } from "vue-quill-editor";
import Quill from 'quill';
import Parchment from 'parchment';

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

//自定义样式一定要在原插件css下面引入
import "@/assets/css/quillEditor.css"

import imageResize from 'quill-image-resize-module';//引入图片编辑组件
Quill.register('modules/imageResize',imageResize);//富文本实现图片编辑功能

// 自定义字体
var Font = Quill.import('formats/font')
Font.whitelist = fonts
Quill.register(Font, true)

//目的是修改富文本内容居中不了的问题
let config = {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['right', 'center', 'justify']
};

let AlignStyle = new Parchment.Attributor.Style('align', 'text-align', config);
export { AlignStyle };
var Align = Quill.import('attributors/style/align');
Align.whitelist = ['right', 'center', 'justify'];
Quill.register(Align, true);

var Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

//修改富文本内容居中不了的问题

export default {
    components: {
        quillEditor
    },

    props: ['currentContent'],

    data(){
        return {
            content: '',
            editorOption: {
                placeholder: "",
                theme: "snow", // or 'bubble'
                placeholder: "请输入文章内容",
                modules: {
                    toolbar: {
                        container: toolbarOptions,
                        // container: "#toolbar",
                        handlers: {
                            image: function (value) {
                                if (value) {
                                    // 触发input框选择图片文件
                                    document.querySelector(".avatar-uploader input").click();
                                } else {
                                    this.quill.format("image", false);
                                }
                            },
                        },
                    },
                    imageResize: {//控制图片编辑的，实现功能就是这一段代码
                        displayStyles: {
                            backgroundColor: "black",
                            border: "none",
                            color: "white",
                        },
                    modules: ["Resize", "DisplaySize", "Toolbar"],
                    },
                },
            },

            serverUrl: this.$apiBaseUrl + "/file/upload",

            header: {
                // token: sessionStorage.token
            }
        }
    },

    methods: {
        onEditorBlur() {
        //失去焦点事件
        },
        onEditorFocus() {
        //获得焦点事件
        },
        onEditorChange() {
            //内容改变事件
            this.$emit("input", this.content);
        },

        // 富文本图片上传前
        beforeUpload() {
            // 显示loading动画
            this.quillUpdateImg = true;
        },

        uploadError(){},

        uploadSuccess(res, file){
            console.log(res);
            // res为图片服务器返回的数据
            // 获取富文本组件实例
            let quill = this.$refs.myQuillEditor.quill;
            // 如果上传成功
            if (res.code == 10200) {
                // 获取光标所在位置
                let length = quill.getSelection().index;
                // 插入图片  res.url为服务器返回的图片地址
                quill.insertEmbed(length, "image", res.data.url);
                // 调整光标到最后
                quill.setSelection(length + 1);
            } else {
                this.$message.error("图片插入失败");
            }
            // loading动画消失
            this.quillUpdateImg = false;
        },

        showCode(){
            console.log("生成的代码:", this.content)
        }
    },

    watch: {
        currentContent(newValue, oldValue){
            this.content = newValue
        }
    }
}
</script>

<style scoped>
    .wrapper{
        height: 100%;
    }

    ::v-deep .ql-editor{
         height:400px;
     }

    img{
        width: 100px;
    }
</style>